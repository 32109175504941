<template>
	<section class="content">
        <div class="row justify-content-center mb-5">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-6">
                            <div id="demo" class="carousel slide" data-interval="false">
                                <div class="carousel-inner shadow-sm">
                                    <div class="carousel-item active" v-bind:class="`${lowerCase(myMembership.title)}`" :id="myMembership.level">
                                        <img :src="loadImgBadge(myMembership.title)" class="d-block" width="15%" :alt="`${lowerCase(myMembership.title)} badge`" />
                                        <div class="carousel-caption d-block">
                                            <h5 style="color: #707070;text-decoration: none;font-size: 16pt;">{{ myMembership.title }}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="clearfix"></div>
                            <div>
                                <div class="mb-2" style="position: relative; align-items: center;">
                                    <div class="progress progress-membership mt-3">
                                        <div class="progress-bar bg-success" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                    <img :src="logoBar" class="progress-badge" width="4%" alt="silver badge" />
                                </div>
                                <span v-html="msgMembership"></span>
                            </div>
                    </div>
                    <div class="col-md-6">
                        <table class="table" style="line-height: 40px;">
                            <thead>
                                <tr>
                                    <th scope="col">Kriteria Membership</th>
                                    <th scope="col">Performa Saat Ini</th>
                                    <th scope="col">Target Level Selanjutnya</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Total Kiriman</td>
                                    <td class="text-bold" v-bind:style="`${form.total < (!form.next_min_order ? form.curr_min_order : form.next_min_order) ? 'color:#D7422D;' : 'color:#58BC71;'}`">{{ form.total }}</td>
                                    <td>Tingkatkan hingga <strong>{{ (!form.next_min_order ? form.curr_min_order : form.next_min_order) }}</strong> kiriman</td>
                                </tr>
                                <tr>
                                    <td>Kiriman Sukses</td>
                                    <td class="text-bold" v-bind:style="`${form.rasio_success < 90 ? 'color:#D7422D;' : 'color:#58BC71;'}`">{{ form.rasio_success }} %</td>
                                    <td>Tingkatkan hingga  <strong>90 %</strong></td>
                                </tr>
                                <tr>
                                    <td>Persentase Return</td>
                                    <td class="text-bold" v-bind:style="`${form.rasio_retur >= 10 ? 'color:#D7422D;' : 'color:#58BC71;'}`">{{ form.rasio_retur }} %</td>
                                    <td>Pertahankan dibawah <strong>10 %</strong></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="p-1"></div>
                    <div class="col-md-12 shadow-sm mb-4">
                        <div class="row">
                            <div class="col-auto">
                            <label class="control-label">
                                <h5>Kamu berhasil hemat:</h5>
                            </label>
                            </div>
                            <div class="col-auto">
                                <h4 class="text-bold">
                                    Rp {{ formatMoney(form.total_cashback) }}
                                </h4>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="card artikel shadow">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12 form-group mb-4">
                                        <label class="control-label">Ajak Temanmu Untuk Lebih Hemat Melalui Link Ini</label>
                                        <!-- <input id="referral" class="form-control" type="text" name="referral" value="PAKAIINI"/> -->
                                        <span class="btn-group d-block mb-3 d-flex">
                                            <button class="btn btn-lg border rounded-left w-100" style="cursor: alias;">{{referralLink}}</button>
                                            <button ref="btnCopy" class="btn btn-lg btn-primary" data-toggle="tooltip" data-placement="top" title="Copy to clipboard" type="button" @click="copyCode"><span class="fa fa-copy"></span></button>
                                        </span>
                                    </div>
                                    <div class="col-sm-12"><label class="control-label">Artikel Membership</label></div>
                                    <div class="col-sm-6">
                                        <div class="card mw-100" style="width: 14rem;">
                                            <img src="@/assets/img/member/cover-artikel-1.png" class="card-img-top" alt="cover artikel 1">
                                            <div class="card-body">
                                                <a href="https://help.boscod.com/cara-kerja-membership-boscod/" class="card-artikel" target="_blank" rel="noopener noreferrer">
                                                    <p class="card-text">Kenalan dengan membership bosCOD, dan raih banyak keuntungan!</p>
                                                </a>
                                                <div class="p-1"></div>
                                                <span class="text-mute">16 Nov 2022</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="card mw-100" style="width: 14rem;">
                                            <img src="@/assets/img/member/cover-artikel-2.png" class="card-img-top" alt="cover artikel 2">
                                            <div class="card-body">
                                                <a href="https://help.boscod.com/benefit-membership-boscod/" class="card-artikel" target="_blank" rel="noopener noreferrer">
                                                    <p class="card-text">Kenali benefit membership bosCOD, dan dapatkan diskon pengiriman kamu.</p>
                                                </a>
                                                <div class="p-1"></div>
                                                <span class="text-mute">16 Nov 2022</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="card artikel right shadow" style="height: 25.1rem;">
                            <div class="card-body d-flex align-items-center">
                                <div class="row left-card-membership flex-row-reverse">
                                    <div class="col-sm-6">
                                        <div class="card my-auto mw-100" style="width: 15rem;">
                                            <div class="card-body">
                                                <img src="@/assets/img/member/next-steps.svg" class="card-img-top" alt="cover next step">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 left-card-membership-col my-auto">
                                        <div class="card">
                                            <div class="card-body">
                                                <h5 class="card-title mb-3"><strong>Tentang ketentuan Membership</strong></h5>
                                                <a href="https://help.boscod.com/ketentuan-membership-boscod/" target="_blank" rel="noopener noreferrer" class="btn btn-outline-primary">Baca Selengkapnya</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { authFetch, auth, formatCurrency } from "@/libs/hxcore";
import datepicker from '@/components/Datepicker';
import $ from "jquery";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import moment from "moment";

export default {
    data(){
        return {
            errors: [],
            url: window.$apiUrl,
            loadingContent: false,
            roles: "",
            groups: [],
            groupMembership: [],
            myMembership: {},
            show: false,
            method: "PUT",
            form: {},
            progress: {},
            filter: {},
            status: "create",
            url: '',
            // dt1: moment().startOf("month"),
            // dt2: moment(),
            slide: {
                level: 1,
                title: 'SILVER',
                min_order: 0,
                selisih: 0
            },
            bar: {
                title: 'SILVER'
            },
            logoBadge: '',
            msgMembership: '',
            valueBar: 0,
            logoBar: '',
            month: moment().startOf("month").format("MMMM YYYY"),
            setFormat: {
                toDisplay: function(date, format, language) {
                    return moment(date).format("MMMM YYYY");
                },
                toValue: function(date, format, language) {
                    return moment(date).format("MM/YYYY");
                }
            },
            referralCode: "",
            referralLink: "",
        };
    },
    created: function () {
        this.userInfo = auth.user();

        // set filter date
        if(this.filter.month)
        {
            this.month = moment(this.filter.month).format('MM/YYYY');
        } else {
            this.filter.month = moment(this.month).format('MM/YYYY');
        }

        if (this.logoBar == '') {
            this.logoBar = require(`../assets/img/member/silver.svg`);
        }

        // load data membership
        this.loadDataMembership();
        this.loadMembership();
    },
    methods: {
        copyCode() {
			var self = this;
            navigator.clipboard.writeText(this.referralLink).then(function() {
                $(self.$refs.btnCopy).attr('title','Copied!').tooltip('_fixTitle').tooltip('show').attr('title','Copy to clipboard').tooltip('_fixTitle')
            }, function(err) {
                console.error(err);
            });
		},
        lowerCase: function (val){
            if (val) {
                return val.toLowerCase();
            } else {
                return val;
            }
        },
        getReferralCode() {
            authFetch("/users/profile/affiliated")
            .then((res) => {
                if (res.status === 201) {} else if (res.status === 400) {}
                return res.json();
            })
            .then((js) => {
                if (js.success) {
                    this.referralCode = js.referral_code;
                    this.referralLink = js.url;
                }
            });
        },
        firstCapitalize: function (s) {
            if (typeof s !== 'string') return '';
            return s.charAt(0).toUpperCase() + s.slice(1);
        },
        setMonth: function (val) {
            this.filter.month = moment(val).format('MM/YYYY');
            this.loadDataMembership();
        },
        loadDataMembership: function () {
            authFetch("/shipper/membership")
            .then((res) => {
                if (res.status === 201) {} else if (res.status === 400) {}
                return res.json();
            })
            .then((js) => {
                this.form = js.data;
                this.loadText();
                $('.loading-overlay').removeClass('show');
            });
        },
        loadMembership: function () {
            let self = this;

            authFetch("/shipper/membership/membership_data")
            .then((res) => {
                if (res.status === 201) {} else if (res.status === 400) {}
                return res.json();
            })
            .then((js) => {
                self.myMembership = js.data;
                self.progress = js.data;
                self.loadProgressBar();
            });
        },
        formatMoney(val) {
            if (!val) {
                return 0;
            } else {
                return formatCurrency(val);
            }
        },
        onChangeSlide() {
            this.loadText(this.slide.id);
        },
        loadImgBadge(member) {
            const fileName = this.lowerCase(member);
            return require(`../assets/img/member/${fileName ? fileName : 'silver'}.svg`) // the module request
        },
        loadProgressBar() {
            let params = this.progress;
            let rataRata = (params.total / params.min_order) * 100;

            $(".progress-bar")
            .css("width", `${rataRata}%`)
            .attr("aria-valuenow", rataRata);

            // set image bar
            let member = params.next_member_title;
            if (params.level == 3 || params.level > 3) {
                member = 'platinum';
            }
            const fileName = this.lowerCase(member);
            this.logoBar = require(`../assets/img/member/${fileName ? fileName : 'silver'}.svg`);
        },
        loadText() {
            let params = this.progress;
            let level = params.level;
            let sum = params.next_min_order-params.total;
            let title = this.firstCapitalize(this.lowerCase(params.title));

            if (level < 3) {
                if (sum < 0) {
                    let next_title = this.firstCapitalize(this.lowerCase(params.next_member_title));
                    this.msgMembership = `Tunggu ya! Sebentar lagi kamu akan jadi <b>Member ${next_title}</b>`;
                } else {
                    let next_title = this.firstCapitalize(this.lowerCase(params.next_member_title));
                    this.msgMembership = `Kirim ${sum} paket lagi bulan ini untuk jadi <b>Member ${next_title}</b>`;
                }
            } else {
                this.msgMembership = `Selamat, kamu udah jadi <b>Member ${title}</b>`;
            }
        },
    },
    components: {
        vSelect,
        datepicker
    },
    mounted() {
        let self = this;
        self.loadDataMembership();
        self.loadMembership();
        self.getReferralCode();

        $(this.$refs.btnCopy).tooltip().on('mouseleave',function(){$(this).tooltip('hide')});
        $('#demo').on('slid.bs.carousel', function (e) {
            var ele = $('#demo .carousel-indicators li.active');
            self.slide.title = ele.data('value');
            self.slide.id = ele.data('slideTo');
            self.onChangeSlide();
        });
    },
}
</script>
<style type="text/css">
.carousel-indicators .active {
	height: 11px;
	width: 34px;
    background-color: #FF7203;
    border-radius: 100px;
}

.carousel-item, .carousel-item.active {
    height: 210px;
}

.carousel-item-next,
.carousel-item-prev {
	height: 100%;
    margin-bottom: 79px;
}

.carousel-indicators li {
	border-top: none;
	border-bottom: none;
	height: 11px;
    width: 15px;
	display: inline-block;
	background-color: #5A8AA8;
    border-radius: 100px;
}

.carousel-inner {
	height: 210px;
    border-radius: 11px;
}

.carousel-indicators {
	display: block;
	text-align: center;
	width: 70%;
    bottom: -5px;
}

.carousel-control-prev-icon {
    background: #ffffff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000000' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") center/contain no-repeat;
    background-origin: content-box;
    border-radius: 50%;
    padding: 9px;
    width: 35px;
    height: 35px;
    box-shadow: 0 0 30px 4px #aaaaaa;
}

.carousel-control-next-icon {
    background: #ffffff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000000' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") center/contain no-repeat;
    background-origin: content-box;
    border-radius: 50%;
    padding: 9px;
    width: 35px;
    height: 35px;
    box-shadow: 0 0 30px 4px #aaaaaa;
}

.carousel-caption {
    bottom: 12px;
}

.carousel-inner img {
	background-size: cover;
	margin-top: 35px;
	margin-left: auto;
	margin-right: auto;
}

.silver {
    background-image: url("~@/assets/img/member/background-silver-large.svg");
}
.gold {
    background-image: url("~@/assets/img/member/background-gold-large.svg");
}
.platinum {
    background-image: url("~@/assets/img/member/background-platinum-large.svg");
}

.table {
    margin-bottom: 0;
}

.col-auto {
    bottom: -7px;
    padding: 12px;
}

.progress-membership {
    position: relative;
}

.progress-badge {
    position: absolute;
    right: 0;
    top: -3px;
    width: 24px;
}

.progress {
    border-radius: 10px;
    width: 520px;
    max-width: 100%;
}

.artikel {
    margin-right:20px!important;
}

a.card-artikel:link, a.card-artikel:visited {
    color: #000000;
}

a.card-artikel:hover, a.card-artikel:active {
    color: #000000;
}

@media (max-width:575px) {
    .artikel {
        margin-right:0px!important;
    }
}
</style>